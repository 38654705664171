.navbar-logo {
    height: 48px;
}

.bg-custom {
    background: linear-gradient(#007871, #225374);
}

.contact-button {
    width: 100%;
}

.delivery-file {
  max-width: 100%;
  margin: 0 auto;
  max-height: 400px;
}

.delivery-file-wrapper.SI {
  transform: rotate(270deg) ;
}

.delivery-file-wrapper {
  position: relative;
}

body {
  background-color: #007871;
}

.download-button {
  position: absolute;
  top: 5px;
  right: 5px;
}

.float-right-top {
    position: absolute;
    right: 3px;
    top: 3px;
}

.flag {
    margin-right: 4px;
}

.flag-selected {
    border: 2px solid #666;
}

.flag-not-selected {
    border: 2px solid white;
}